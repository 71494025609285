// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("select2");
require("pdfjs-dist/build/pdf");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers";
import "codebase/app";
import "cocoon-js";
import "./sweetalert";
import "multiselect";
import "jquery.quicksearch";

document.addEventListener("turbolinks:load", () => {
  // Update doctor list dynamically in gadget transfer view
  $("#giveaway_gadget_transfer_doctor").select2({
    ajax: {
      url: "/admin/giveaway_gadgets/return_doctors",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          query: params.term,
          page: params.page || 1,
        };
      },
      processResults: function (data, params) {
        var results = $.map(data, function (item) {
          // Push each item into the existing collection
          $("#select2-giveaway_gadget_transfer_doctor-results").push({
            id: item[1],
            text: item[0],
          });

          return {
            id: item[1],
            text: item[0],
          };
        });

        return {
          results: results,
          pagination: {
            more: params.page * 30 < data.total_count,
          },
        };
      },
      cache: true,
    },
    placeholder: "Wyszukaj lekarza (wpisz minimum 3 litery)",
    minimumInputLength: 3,
    language: {
      inputTooShort: function (args) {
        var remainingChars = args.minimum - args.input.length;
        return "Proszę wpisać jeszcze " + remainingChars + " dodatkowych liter";
      },
      searching: function () {
        return "Trwa wyszukiwanie...";
      },
    },
  });

  $(".multiselect-brands").multiSelect({
    selectableHeader:
      "<div class='mb-1 text-black font-size-h6 text-sm-center'> Dostępne Brandy </div>",
    selectionHeader:
      "<div class='mb-1 text-black font-size-h6 text-sm-center'> Wybrane Brandy </div>",
  });

  $(".multiselect").multiSelect({
    selectableHeader:
      "<input type='text' class='search-input' autocomplete='off' placeholder='Wyszukaj dostępne'>",
    selectionHeader:
      "<input type='text' class='search-input' autocomplete='off' placeholder='Wyszukaj wybrane'>",
    afterInit: function (ms) {
      var that = this,
        $selectableSearch = that.$selectableUl.prev(),
        $selectionSearch = that.$selectionUl.prev(),
        selectableSearchString =
          "#" + that.$container.attr("id") + " .ms-elem-selectable:not(.ms-selected)",
        selectionSearchString =
          "#" + that.$container.attr("id") + " .ms-elem-selection.ms-selected";

      that.qs1 = $selectableSearch.quicksearch(selectableSearchString).on("keydown", function (e) {
        if (e.which === 40) {
          that.$selectableUl.focus();
          return false;
        }
      });

      that.qs2 = $selectionSearch.quicksearch(selectionSearchString).on("keydown", function (e) {
        if (e.which == 40) {
          that.$selectionUl.focus();
          return false;
        }
      });
    },
    afterSelect: function () {
      this.qs1.cache();
      this.qs2.cache();
    },
    afterDeselect: function () {
      this.qs1.cache();
      this.qs2.cache();
    },
  });

  $("form").each(function () {
    let $spinner = $(this).find(".js-spinner");

    if ($spinner) {
      $spinner.hide();

      $(this).submit(function () {
        $spinner.show();
      });
    }
  });

  $("#giveaway_gadget_transfer_quantity").on("input", function () {
    $("#giveaway_gadget_transfer_quantity").removeClass("required-error");
  });
  if ($(".alert-danger").text().toLowerCase().indexOf("ilość") >= 0) {
    $("#giveaway_gadget_transfer_quantity").addClass("required-error");
  }
});

function reverseString(str) {
  return str.split("").reverse().join("");
}

// Destroy javascript generated html, so turbolinks won't cache it
document.addEventListener("turbolinks:before-cache", function () {
  $(".js-select2").select2("destroy");
  $(".multiselect").multiSelect("destroy");
});

//Copy doctor info
$(document).on("turbolinks:load", function () {
  $(".copy-button").on("click", function () {
    var textToCopy = $(this).data("clipboard-text");
    var tempInput = $("<input>");
    $("body").append(tempInput);
    tempInput.val(textToCopy).select();
    document.execCommand("copy");
    tempInput.remove();
    $(this).addClass("copied");
    setTimeout(() => {
      $(this).removeClass("copied");
    }, 1000);
  });
});

//Copy magic link
$(document).on("turbolinks:load", function () {
  function waitForElement() {
    if ($(".copy-magic-button").length > 0) {
      $(".copy-magic-button").on("click", function () {
        var textToCopy = $(this).data("clipboard-text");
        var tempInput = $("<input>");
        $("body").append(tempInput);
        tempInput.val(textToCopy).select();
        document.execCommand("copy");
        tempInput.remove();
        $(this).addClass("copied");
        setTimeout(() => {
          $(this).removeClass("copied");
        }, 1000);
      });
    } else {
      setTimeout(waitForElement, 100);
    }
  }
  waitForElement();
});

//External conference
$(document).on("turbolinks:load", function () {
  const externalCheckbox = document.getElementById("conference_external");
  const supervisorField = document.getElementById("supervisor_field");
  function toggleSupervisorField() {
    if (externalCheckbox.checked) {
      supervisorField.style.display = "block";
    } else {
      supervisorField.style.display = "none";
    }
  }

  externalCheckbox.addEventListener("change", toggleSupervisorField);

  toggleSupervisorField();
});

//Infarma certificate
$(document).on("turbolinks:load", function () {
  const certificateField = document.getElementById("certificate_field");
  const infarmaCheckbox = document.getElementById("infarma_certificate_present");
  const infarmaInput = document.getElementById("conference_infarma_certificate");

  function toggleCertificateField() {
    if (infarmaCheckbox.checked) {
      certificateField.style.display = "none";
    } else {
      certificateField.style.display = "block";
    }
  }

  toggleCertificateField();

  infarmaCheckbox.addEventListener("change", toggleCertificateField);

  infarmaInput.addEventListener("input", function () {
    certificateField.style.display = "block";
  });
});
